export const DATABASE_COLUMN_VISUAL_TYPE = [
  {
    techName: 'select',
    name: 'Список',
  },
  {
    techName: 'search',
    name: 'Поиск',
  },
  {
    techName: 'notActive',
    name: 'Текст',
  },
];

export const DATABASE_COLUMN_SORT_TYPE = [
  {
    techName: 'asc',
    name: 'По возрастанию',
  },
  {
    techName: 'desc',
    name: 'По убыванию',
  },
];

export const DEFAULT_DATABASE_OBJECT = {
  name: '',
  url: '',
  user: '',
  password: '',
};
export const SECRET_PASSWORD = 't6w9z$C&F)J@NcRfUjXn2r5u7x!A%D*G';

export const DEFAULT_VALUE_OBJECT = {
  id: null,
  columns: [],
};

export const VISUAL_TYPES = {
  search: 'search',
  select: 'select',
  notActive: 'notActive',
};

export const SORT_TYPES = {
  asc: 'asc',
  desc: 'desc',
};
