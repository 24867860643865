<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <input type="hidden" v-model="localColumns">
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__database-element': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="database__header">
        <div class="hm-form__element-label">
          {{ name }}
          <span v-if="required">*</span>
        </div>
        <esmp-button
          view="outline"
          size="small"
          @click="clearColumns"
        >
          Сбросить данные
        </esmp-button>
      </div>
      <div class="database">
        <esmp-loader v-if="isLoading" fix />
        <div
          class="database__columns"
          v-if="localColumns.length"
        >
          <div
            class="database__column"
            v-for="(column, index) in localColumns"
            :key="`column-${index}`"
          >
            <database-search
              v-if="column.visualType === VISUAL_TYPES.search"
              :index="index"
              :column="column"
              :is-clearing="isClearing"
              :required="column.required"
              :error-message="column.required && !column.value ? v.errors[0] : ''"
              @update-search-column="updateSearchColumn"
              @set-column-value="setColumnValue"
            />
            <esmp-select
              v-else-if="column.visualType === VISUAL_TYPES.select"
              v-model="column.value"
              :placeholder="`${column.label || column.name}${column.required ? ' *' : ''}`"
              @on-select="setColumnValue($event, index)"
              @on-clear="clearColumnValue(index)"
              clearable
              :error-message="column.required && !column.value ? v.errors[0] : ''"
            >
              <esmp-select-option
                v-for="(item, optionIndex) in column.values"
                :key="`option-${item.value}-${optionIndex}`"
                :value="item.value"
              >
                {{ item.value }}
              </esmp-select-option>
            </esmp-select>
            <esmp-select
              v-else-if="column.visualType === VISUAL_TYPES.notActive"
              v-model="column.value"
              :placeholder="`${column.label || column.name}${column.required ? ' *' : ''}`"
              disabled
              :error-message="column.required && !column.value ? v.errors[0] : ''"
            >
              <esmp-select-option
                v-for="(item, optionIndex) in column.values"
                :key="`option-${item.value}-${optionIndex}`"
                :value="item.value"
              >
                {{ item.value }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import isEmpty from 'lodash/isEmpty';
import { SORT_TYPES, VISUAL_TYPES } from '@/constants/database';
import DatabaseSearch from './DatabaseSearch.vue';

export default {
  name: 'HmDatabase',
  mixins: [uid],
  components: {
    DatabaseSearch,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    databaseId: {
      type: Number,
      default: undefined,
    },
    databaseColumns: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      localColumns: [],
      selectedSortOrder: null,
      isClearing: false,
    };
  },
  computed: {
    isAllSelectsFielded() {
      const selectColumns = this.localColumns.filter((column) => column.visualType === this.VISUAL_TYPES.search
        || column.visualType === this.VISUAL_TYPES.select);
      const emptyColumns = selectColumns?.filter((col) => isEmpty(col.value));
      return !(emptyColumns?.length);
    },
  },
  watch: {
    localColumns: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
  methods: {
    clearColumnValue(index) {
      this.setColumnValue(null, index);
    },
    updateSearchColumn(column, columnIndex) {
      this.localColumns[columnIndex] = column;
      this.getColumns();
    },
    setColumnValue(currentValue, index) {
      this.localColumns[index] = {
        ...this.localColumns[index],
        value: currentValue?.value,
        searchQuery: currentValue?.value,
      };
      this.getColumns();
    },
    getColumns() {
      this.isLoading = true;
      if (!this.isAllSelectsFielded) {
        this.localColumns = this.localColumns.map((column) => ({
          ...column,
          value: column.visualType === VISUAL_TYPES.notActive ? '' : column.value,
          searchQuery: column.visualType === VISUAL_TYPES.notActive ? '' : column.searchQuery,
        }));
      }
      this.$API.database.getColumns({
        databaseId: this.databaseId,
        columns: [...this.localColumns].map((el) => ({
          id: el.id,
          required: el.required,
          value: el.value,
          filterValues: el.filterValues || [],
          label: el.label,
          name: el.name,
          tableName: el.tableName,
          dataType: el.dataType,
          visualType: el.visualType,
          searchQuery: el.searchQuery,
        })),
      }).then(({ data }) => {
        if (!data.columnDtoList) {
          this.localColumns = [];
        } else {
          this.formattedColumns(data.columnDtoList);
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    formattedColumns(columns) {
      this.localColumns = [...columns.map((i) => {
        let localValues = i.values;
        if (i.sortType) {
          let numbers = [];
          let strings = [];
          i.values.forEach((e) => (Number.isNaN(e.value) ? strings : numbers).push(e));
          if (i.sortType === SORT_TYPES.asc) {
            numbers = numbers.sort((a, b) => Number(a.value) - Number(b.value));
            strings = strings.sort((a, b) => a.value.localeCompare(b.value));
            localValues = numbers.concat(strings);
          } else if (i.sortType === SORT_TYPES.desc) {
            numbers = numbers.sort((a, b) => Number(b.value) - Number(a.value));
            strings = strings.sort((a, b) => b.value.localeCompare(a.value));
            localValues = numbers.concat(strings);
          }
        }
        /** Проверка, если с сервера в массиве values приходит всего одно значение,
         то автоматически присвоится это значение в поле value для каждой колонки.
         */
        // eslint-disable-next-line no-nested-ternary
        const localValue = localValues.length === 1 ? localValues[0].value : i.value ? i.value : null;
        return {
          ...i,
          value: localValue,
          values: localValues,
          searchQuery: localValue,
        };
      })];
      this.selectedSortOrder = null;
    },
    clearColumns() {
      this.isClearing = true;
      this.formattedColumns(this.databaseColumns);
      this.$nextTick(() => {
        this.isClearing = false;
      });
    },
  },
  created() {
    this.VISUAL_TYPES = VISUAL_TYPES;
    this.formattedColumns(this.databaseColumns);
  },
};
</script>
<style lang="scss">
.database {
  position: relative;

  &__column {
    margin-bottom: $base-gutter;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $base-gutter;
  }
}
</style>
